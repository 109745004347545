<template>
  <div class="m-certificate-check-form" >
    <form
      @submit.prevent="checkCode"
      class="center"
    >
      <div class="form__title">
        {{ $t('Check certificate validity period') }}
      </div>
      <div
        v-if="!barcodeStatus"
        class="form__row"
      >
        <SfInput
          v-model.trim="barcode"
          name="barcode"
          type="number"
          class="form__element"
          :label="$t('Type certificate number')"
          :valid="!$v.barcode.$error"
          :error-message="$t('Field is required.')"
        />
        <SfButton
          :disabled="this.$v.$invalid"
          class="sf-button--primary form__submit"
        >
          {{ $t('Check') }}
        </SfButton>
      </div>
      <div
        v-else
        class="form__result"
      >
        <div
          v-if="barcodeState"
          class="form__result-title">
          {{ barcodeState }}
        </div>
        <div
          v-if="barcodeExpired"
          class="form__result-main"
        >
          <div class="form__result-caption">{{ $t('Expired') }}: </div>
          <div class="form__result-value">{{ barcodeExpired }}</div>
        </div>
      </div>
      <div
        v-if="barcodeCheckProcessed"
        class="data-loader__wrap"
      >
        <SfLoader class="data-loader"/>
      </div>
    </form>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger'
import { required } from 'vuelidate/lib/validators'
import { SfButton } from '@storefront-ui/vue'
import { mapActions } from 'vuex'
import SfLoader from 'theme/components/storefront-override/SfLoader'
import SfInput from 'theme/components/storefront-override/SfInput'

export default {
  name: 'MCertificateCheckForm',
  components: {
    SfInput,
    SfLoader,
    SfButton
  },
  data () {
    return {
      barcode: '',
      barcodeStatus: null,
      barcodeCheckProcessed: false
    };
  },
  validations () {
    return {
      barcode: {
        required
      }
    }
  },
  computed: {
    barcodeState () {
      const barcodeStatuses = {
        0: this.$t('Certificate is inactive'),
        1: this.$t('Certificate is non-activated'),
        2: this.$t('Certificate is activated'),
        3: this.$t('Certificate is used'),
        4: this.$t('Certificate is not found')
      };

      return barcodeStatuses[this.barcodeStatus?.status] || '';
    },
    barcodeExpired () {
      return this.formatDate(this.barcodeStatus?.end_date) || ''
    }
  },
  mounted () {
    this.$emit('componentMounted')
  },
  methods: {
    ...mapActions({
      checkGiftCertificate: 'giftCard/checkGiftCertificate',
      spawnNotification: 'notification/spawnNotification'
    }),
    async checkCode () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.onFailure(this.$t('Please fix the validation errors'));
        return
      }

      try {
        this.barcodeCheckProcessed = true
        const response = await this.checkGiftCertificate(this.barcode, { root: true })

        if (response?.code === 200) {
          this.barcodeStatus = response.result
        } else {
          this.onFailure(response?.result?.errorMessage);
        }
      } catch (err) {
        Logger.error(err, 'giftCertificate/checkBarcode')()
      } finally {
        this.barcodeCheckProcessed = false
      }
    },
    formatDate (date) {
      const newDate = new Date(date)
      if (isNaN(newDate.valueOf())) return date

      const day = String(newDate.getDate()).padStart(2, '0');
      const month = String(newDate.getMonth() + 1).padStart(2, '0');
      const year = String(newDate.getFullYear());

      return `${day}.${month}.${year}`;
    },
    onFailure (message) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(message),
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-certificate-check-form {
  position: relative;
  background-color: var(--orange-light-background);
  padding: var(--spacer-30) var(--spacer-20);

  @media (min-width: $desktop-min) {
    width: 990px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    overflow: hidden;
    padding: var(--spacer-40) 0;
  }
}

.form {
  &__title {
    text-align: center;
    font-size: var(--font-size-16);
    line-height: var(--font-size-19);
    font-weight: var(--font-medium);
    color: var(--black);
    margin: 0 0 var(--spacer-10);

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-24);
      line-height: var(--font-size-25);
      font-weight: var(--font-medium);
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacer-10);
    margin-top: var(--spacer-17);

    @media (min-width: $tablet-min) {
      flex-direction: row;
      margin-top: var(--spacer-28);
    }
  }

  &__element {
    width: 100%;

    @media (min-width: $tablet-min) {
      max-width: 400px;
    }
  }

  &__submit {
    width: 100%;
    height: var(--spacer-56);

    @media (min-width: $tablet-min) {
      width: fit-content;

      button {
        padding-left: var(--spacer-16) var(--spacer-40);
      }
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacer-17);
    gap: var(--spacer-6);
    font-size: var(--font-size-20);
    line-height: var(--font-size-24);
    font-weight: var(--font-medium);
    min-height: 144px;
    text-align: center;

    @media (min-width: $tablet-min) {
      min-height: 78px;
    }

    &-main {
      display: flex;
    }

    &-value {
      margin-left: var(--spacer-6);
    }
  }
}

.data-loader__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  opacity: .8;
}

.data-loader {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-no-error-massage {
  ::v-deep .sf-input__error-message {
    display: none;
  }
}

::v-deep {
  .sf-input {
    width: 100%;
    --input-label-font-size: var(--font-size-15);
    --input-label-color: var(--dark-gray);
    --input-label-top: 50%;

    &--has-text {
      .sf-input__label {
        --input-label-top: 20%;
        --input-label-font-size: var(--font-size-12);
      }
    }

    input {
      height: var(--spacer-56);
      border: 1px solid transparent;
      background: var(--white);
      --input-label-font-size: var(--font-size-14);

      &:focus {
        border: 1px solid var(--orange);
        box-sizing: border-box;

        & ~ * {
          --input-label-top: 20%;
          --input-label-color: var(--dark-gray);
          --input-label-font-size: var(--font-size-12);
        }
      }

      &:focus-visible {
        outline: none;
        border: 1px solid var(--orange);
        box-sizing: border-box;
      }
    }

    &__bar {
      display: none;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .sf-input__wrapper {
    margin-bottom: 0;
  }

  .sf-input__error-message:empty {
    display: none;
  }
}
</style>
